import React from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { createAvatar } from "@dicebear/core";
import { identicon } from "@dicebear/collection";

const Comment = ({ comment, handleDelete }) => {
  //   const timeDifferenceInMinutes = Math.floor(
  //     (new Date() - new Date(comment.dateTime)) / (1000 * 60)
  //   );

  const avatar = React.useMemo(() => {
    if (!comment || !comment.user._id) return null;
    return createAvatar(identicon, {
      seed: comment.user._id,
    }).toDataUri();
  }, [comment?.user._id]);

  function convertDateTimeFormat(dateTimeString) {
    const dateTime = new Date(dateTimeString).toLocaleString("en-US", {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    return dateTime;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: "10px",
      }}
    >
      <div
        style={{
          width: "40px",
          height: "40px",
          borderRadius: "50%",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          backgroundColor: "#ffffff",
          marginRight: "10px",
        }}
      >
        <img
          src={avatar}
          alt="Avatar"
          style={{
            width: "20px",
            height: "20px",
          }}
        />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        {comment.user && comment.user.type !== "user" && (
          <span
            style={{
              color: "white",
              fontSize: "12px",
              marginBottom: "5px",
            }}
          >
            <i className="fas fa-thumbtack"></i> Pinned
          </span>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#00000062",
            width: "100%",
            padding: "10px",
            borderRadius: "10px",
          }}
        >
          <div>
            {comment.user && comment.user.type !== "user" && (
              <span
                style={{
                  backgroundColor: "#ff8000",
                  color: "#000000",
                  paddingInline: "5px",
                  paddingBlock: "2px",
                  borderRadius: "10px",
                  fontSize: "12px",
                }}
              >
                {comment.user.organization} <i className="fas fa-check"></i>
              </span>
            )}
            {/* <span
            style={{
              color: "white",
              fontSize: "12px",
            }}
          >
            {comment.user.type}
          </span> */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <span
                style={{
                  color: "white",
                  fontSize: "12px",
                }}
              >
                @{comment.user.username}
              </span>
              <span
                style={{
                  color: "#ffffff76",
                  fontSize: "10px",
                }}
              >
                {convertDateTimeFormat(comment.dateTime)}
              </span>
            </div>
          </div>
          <div>
            <span
              style={{
                color: "white",
                fontSize: "12px",
              }}
            >
              {comment.comment}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Comment;
